<template>
  <div class="me">
    <ul class="nav nav-tabs">
      <li class="active" :title="$t('properties')">
        <a href="#panel-detail-editor" data-toggle="tab" aria-expanded="true">
          <i class="fa fa-panel"></i>
          <span>{{ $tc("panel", 1) }}</span>
        </a>
      </li>
      <li :title="$t('controls')" class="disabled" @click.stop.prevent></li>
      <li :title="$t('events')" class="disabled" @click.stop.prevent></li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane active" id="panel-detail-editor">
        <PanelTypeTitle
          :title="originalTitle"
          @edit="editGeneralPanelProperties"
        />
        <template v-if="canEditProperties">
          <TogglePanel
            v-if="persistent"
            :title="$t('specific_properties')"
            :icon="{
              before: 'fa fa-sliders',
              collapse: 'fa-minus',
              expand: 'fa-plus'
            }"
            :persistent="persistent"
          >
            <slot></slot>
          </TogglePanel>
          <slot v-else></slot>
        </template>
        <template v-else>
          <TogglePanel
            v-if="persistent"
            :title="$t('specific_properties')"
            :icon="{
              before: 'fa fa-sliders',
              collapse: 'fa-minus',
              expand: 'fa-plus'
            }"
            :persistent="persistent"
          >
            <LinkedPanelWarning v-if="isSyncEnabled" />
          </TogglePanel>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import Panels from "@/assets/dashboard/panels.json";
import PanelTypeTitle from "@/components/control-sidebar/property-editors/panel-type-title.vue";
import LinkedPanelWarning from "@/components/control-sidebar/property-editors/linked-panel-warning.vue";
import { isSyncEnabled } from "@/services/dashboard.js";

export default {
  name: "DetailForm",
  props: {
    toggleTitle: {
      type: String,
      required: false,
      default: "properties"
    },
    persistent: {
      type: String,
      required: false,
      default: "toogle_generic_panel"
    }
  },
  components: {
    TogglePanel,
    PanelTypeTitle,
    LinkedPanelWarning
  },
  computed: {
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    originalTitle() {
      if (!this.panel) return "";
      const defPanel =
        Panels.find((p) => p.template.template == this?.panel?.template) ||
        null;
      return defPanel && defPanel?.title || "unknown";
    },
    panelName() {
      return this?.panel?.name || "unknown";
    },
    template() {
      return this.$store.getters["dashboard/editorTemplate"] || null;
    },
    isSyncEnabled() {
      return this.panelName in (this?.template?.linkedPanels || {});
    },
    canEditProperties() {
      return this?.panel?.template == 'SynopticPanel' || (!isSyncEnabled(this.template, this.panelName));
    }
  },
  methods: {
    editGeneralPanelProperties() {
      this.$root.$emit("dashboard:editor", {
        action: "editPanel",
        data: { panelName: this.panel.name, showContentProperties: false }
      });
    }
  }
};
</script>

<style scoped>
.nav {
  display: flex;
}

.nav-tabs {
  min-height: 40px;
}

.nav li {
  flex: 1;
}

.nav li a {
  display: flex;
  justify-content: center;
  width: 100%;
  opacity: 0.6;
}

.nav li a:hover {
  opacity: 1;
}

.nav li.active a {
  opacity: 1;
}

.nav > li > a {
  color: inherit;
}
.nav > li > a > span {
  margin: -2px 0 0 5px;
}
</style>
